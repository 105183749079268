/**
 * --------------------------------------------------------------------------
 * Bootstrap (v5.3.0-alpha1): button.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * --------------------------------------------------------------------------
 */

import { defineJQueryPlugin } from './util/index.js'
import EventHandler from './dom/event-handler.js'
import BaseComponent from './base-component.js'

/**
 * Constants
 */

const NAME = 'range'
const DATA_KEY = 'bs.range'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const SELECTOR_DATA_TOGGLE = '[data-bs-input="range"]'
const EVENT_CLICK_DATA_API = `click${EVENT_KEY}${DATA_API_KEY}`

/**
 * Class definition
 */

class Range extends BaseComponent {
  // Getters
  static get NAME() {
    return NAME
  }
}

/**
 * Data API implementation
 */
// eslint-disable-next-line unicorn/no-array-for-each
document.querySelectorAll('input[type="range"]').forEach(input => {
  const min = input.min.length ? input.min : '0'
  const max = input.max.length ? input.max : '100'
  const val = input.value

  // eslint-disable-next-line prefer-template
  input.style.backgroundSize = (((val - min) * 100) / (max - min)) + '% 100%'
})

EventHandler.on(document, EVENT_CLICK_DATA_API, SELECTOR_DATA_TOGGLE, event => {
  event.preventDefault()

  const min = event.target.min.length ? event.target.min : '0'
  const max = event.target.max.length ? event.target.max : '100'
  const val = event.target.value

  // eslint-disable-next-line prefer-template
  event.target.style.backgroundSize = (((val - min) * 100) / (max - min)) + '% 100%'
})

/**
 * jQuery
 */

defineJQueryPlugin(Range)

export default Range
