/**
 * --------------------------------------------------------------------------
 * Bootstrap (v5.3.0-alpha1): tab.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * --------------------------------------------------------------------------
 */

import { defineJQueryPlugin } from './util/index.js'
import EventHandler from './dom/event-handler.js'
import BaseComponent from './base-component.js'

/**
 * Constants
 */

const NAME = 'nav'
const DATA_KEY = 'bs.nav'
const EVENT_KEY = `.${DATA_KEY}`

const EVENT_KEYDOWN = `keydown${EVENT_KEY}`

const SELECTOR_DATA_NAV = '[data-bs-button="nav-collapse"]'

/**
 * Class definition
 */

class Nav extends BaseComponent {
  constructor(element) {
    super(element)

    if (!this._parent) {
      return
    }

    // Set up initial aria attributes
    EventHandler.on(this._element, EVENT_KEYDOWN, event => this._keydown(event))
  }

  // Getters
  static get NAME() {
    return NAME
  }
}

/**
 * Data API implementation
 */
/* eslint-disable prefer-arrow-callback */
EventHandler.on(document, 'click', SELECTOR_DATA_NAV, function (event) {
  const parent = event.target.parentElement.closest('.side-nav')
  const titles = parent.getElementsByTagName('span')
  const selects = parent.getElementsByTagName('select')

  parent.classList.toggle('nav-collapse')

  const isCollapsed = parent.classList.contains('nav-collapse')

  if (isCollapsed) {
    for (const title of titles) {
      title.classList.add('hidden')
    }

    for (const select of selects) {
      select.classList.add('hidden')
    }
  } else {
    setTimeout(() => {
      for (const title of titles) {
        title.classList.remove('hidden')
      }

      for (const select of selects) {
        select.classList.remove('hidden')
      }
    }, 250)
  }
})

/**
 * Initialize on focus
 */

defineJQueryPlugin(Nav)

export default Nav
