/**
 * --------------------------------------------------------------------------
 * Bootstrap (v5.3.0-alpha1): tab.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * --------------------------------------------------------------------------
 */

import { defineJQueryPlugin } from './util/index.js'
import EventHandler from './dom/event-handler.js'
import BaseComponent from './base-component.js'

/**
 * Constants
 */

const NAME = 'upload'
const DATA_KEY = 'bs.upload'
const EVENT_KEY = `.${DATA_KEY}`

const EVENT_KEYDOWN = `keydown${EVENT_KEY}`

const SELECTOR_DATA_UPLOAD = '[data-bs-input="upload"]'
const SELECTOR_DATA_UPLOAD_FILE = '[data-bs-input="upload-file"]'

/**
 * Class definition
 */

class Upload extends BaseComponent {
  constructor(element) {
    super(element)

    if (!this._parent) {
      return
      // todo: should Throw exception on v6
      // throw new TypeError(`${element.outerHTML} has not a valid parent ${SELECTOR_INNER_ELEM}`)
    }

    // Set up initial aria attributes

    EventHandler.on(this._element, EVENT_KEYDOWN, event => this._keydown(event))
  }

  // Getters
  static get NAME() {
    return NAME
  }
}

/**
 * Data API implementation
 */
/* eslint-disable prefer-arrow-callback */
EventHandler.on(document, 'change', SELECTOR_DATA_UPLOAD, function (event) {
  /* eslint-enable */
  const filesContainer = document.createElement('div')
  filesContainer.setAttribute('class', 'input-group-upload-list')

  const container = event.target.parentElement.querySelector('.input-group-upload-list')
  if (container) {
    container.remove()
  }

  for (let i = 0; i < event.target.files.length; i++) {
    const file = document.createElement('div')
    const fileName = document.createElement('p')
    const closeButton = document.createElement('button')

    fileName.textContent = event.target.files[i].name

    closeButton.setAttribute('type', 'button')
    closeButton.setAttribute('class', 'btn-close')
    closeButton.setAttribute('data-bs-input', 'upload-file')

    file.setAttribute('data-pos', i.toString())
    // file.setAttribute('data-bs-input', 'upload-file')
    file.setAttribute('class', 'input-group-upload-list-item')

    file.append(fileName)
    file.append(closeButton)

    filesContainer.append(file)
  }

  event.target.parentElement.append(filesContainer)
})

EventHandler.on(document, 'click', SELECTOR_DATA_UPLOAD_FILE, function (event) { // eslint-disable-line prefer-arrow-callback
  const dt = new DataTransfer()
  const input = event.target.parentElement.parentElement.parentElement.querySelector('input[data-bs-input="upload"]')

  const { files } = input
  const filePosition = event.target.getAttribute('data-pos')

  for (let i = 0; i < files.length; i++) { // eslint-disable-line unicorn/no-for-loop
    const file = files[i]
    if (Number.parseInt(filePosition, 10) !== i) {
      dt.items.add(file) // here you exclude the file. thus removing it.
    }
  }

  input.files = dt.files // Assign the updates list

  event.target.parentElement.remove()
})

/**
 * Initialize on focus
 */

defineJQueryPlugin(Upload)

export default Upload
